/* Chat Container */
.chat-container {
    background-color: #eccdcd;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

/* User List */
.user-list {
    margin-bottom: 10px;
}

/* Selected User Background */
.bg-primary {
    background-color: #007bff !important;
    color: #ffffff;
}

/* User Badge */
.badge {
    margin-left: 5px;
    font-size: 12px;
}

/* Messages Container */
.messages-container {
    background-color: #f8f9fa;
    border-radius: 20px;
    padding: 10px;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Align messages to the bottom */
    align-items: center;


}

/*  */

/* Admin Message */
.admin-message {
    background-color: #e9ecef;
}

/* User Message */
.user-message {
    background-color: #007bff;
    color: #ffffff;
    width: fit-content;
}

.message {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.messageTop {
    display: flex;
}

.messageImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.messageText {
    padding: 10px;
    border-radius: 20px;
    background-color: #1877f2;
    color: rgb(118, 182, 155);
    max-width: 300px;
}

.messageBottom {
    font-size: 12px;
    margin-top: 10px;
}

.message.own {
    align-items: flex-end;
}

.message.own .messageText {
    background-color: rgb(137, 229, 232);
    color: black;
}