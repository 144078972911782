.rightbar {
  width: auto;
  display: flex;
  -webkit-flex: 0 0 322px;
  -ms-flex: 0 0 322px;
  flex: 0 0 322px;
}


.rightbarWrapper {
  padding: 0px 20px 0 0;
  width: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  margin: 20px 0 0px 0;

}

.birthdayContainer {
  display: flex;
  align-items: center;
}

.birthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.birthdayText {
  font-weight: 300;
  font-size: 15px;
}

.rightbarAd {
  width: 100%;
  border-radius: 10px;
  margin: 30px 0;
}

.rightbarTitle {
  margin-bottom: 20px;
}

.rightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rightbarTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.rightbarInfo {
  margin-bottom: 30px;
}

.rightbarInfoItem {
  margin-bottom: 10px;
}

.rightbarInfoKey {
  font-weight: 500;
  margin-right: 15px;
  color: #555;
}

.rightbarInfoValue {
  font-weight: 300;
}

.rightbarFollowings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rightbarFollowing {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.rightbarFollowingImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.rightbarFollowButton {
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  background-color: #1872f2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.rightbarFollowButton:focus {
  outline: none;
}