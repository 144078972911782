.dropdownwrapper ul li {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;

}

.dropdpper {
  /* overflow: hidden; */
  position: absolute;
  /* top: 0px; */
  /* width: 328px; */
  /* z-index: 991; */
  height: 0px;
  /* margin-top: 57px; */
  transition: height 0.3s ease-in-out 0s, opacity 0.1s ease 0.02s;
  right: 0px;
  margin-right: 4px;
  /* max-height: calc(-65px + 100vh); */
  /* opacity: 0; */
  /* visibility: hidden; */
}

.modelwrapper {
  /* overflow: hidden; */
  position: absolute;
  /* top: 0px; */
  /* width: 328px; */
  /* z-index: 991; */
  height: 0px;
  /* margin-top: 57px; */
  transition: height 0.3s ease-in-out 0s, opacity 0.1s ease 0.02s;
  right: 0px;
  margin-right: 4px;
  /* max-height: calc(-65px + 100vh); */
  /* opacity: 0; */
  /* visibility: hidden; */
}

.dropdown-wrapper {
  position: absolute;
  flex-direction: column;
  text-transform: capitalize;
  /* margin: -19px -111px 0px; */
}

.modalx {
  height: auto;
  /* margin-top: 113px; */
  display: flex;
  flex-direction: column;
  position: initial;
  background: rgb(253, 253, 253);


  /* border-radius: 1rem; */
  /* padding: 13px 2rem; */
  z-index: 30;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
  right: 0;
}

.modaly {
  height: auto;

  display: flex;
  flex-direction: column;
  position: fixed;
  background: rgb(253, 253, 253);
  /* border-radius: 1rem; */
  /* padding: 13px 2rem; */
  z-index: 30;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
  right: 20px;
}

.modal__content {
  text-align: center;
  font-weight: 500;

}

.modal__footer {
  padding: 0;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - -18rem);
    width: auto;
  }
}


.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.modal-overlay {
  position: fixed;
  background-color: rgb(190, 190, 190);
}





.modal-header {
  display: flex;
  place-items: flex-start;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}