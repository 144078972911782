.messenger {

  display: flex;
  justify-content: center;
}

.chatMenu {
  flex: 3.5;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 80%;
  height: 90px;
  padding: 10px;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: rgb(224, 220, 220);
  cursor: default;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox {
    flex: 10;
  }

  .chatOnline {
    flex: 1px;
  }
}


@keyframes squeeze {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

/* Messenger Component */
.messenger {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.user-list {
  flex: 1;
  background-color: #f2f2f2;
  padding: 20px;
}

.message-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}

/* User List */
.user-list .list-group-item {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.user-list .list-group-item:hover {
  background-color: #eaeaea;
}

.user-list .user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-list .username {
  font-weight: bold;
}

.user-list .last-message {
  font-size: 12px;
  color: #777;
}

/* Message Section */
.message-section .message-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.message-section .message-header .user-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-section .message-header .username {
  font-weight: bold;
}

.message-section .message-header .online-status,
.message-section .message-header .offline-status {
  font-size: 12px;
  color: #777;
}

.message-section .message-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.message-section .message-body .message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-section .message-body .message.outgoing {
  align-items: flex-end;
}

.message-section .message-body .message .content {
  background-color: #e2f3ff;
  padding: 10px;
  border-radius: 5px;
}

.message-section .message-body .message .time {
  font-size: 12px;
  color: #777;
}

.message-section .message-footer {
  padding-top: 10px;
}

.message-section .message-footer .alert {
  margin-bottom: 10px;
}

.message-section .message-footer form .form-group {
  margin-bottom: 10px;
}

/* Empty Message */
.empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #777;
  font-size: 18px;
}