:root {
  font-family: "Open Sans", Arial, sans-serif;
  color: #a10aa1 !important;
  margin: 0;
  padding: 0;
  
}

.body {
  font-family: "Open Sans", Arial, sans-serif;
  color: #042e72 !important;
  margin: 0;
  padding: 0;
  overflow: auto;
  border: solid #a10aa1;
}


#navigation {
  width: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #3c2bf4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 70px;
  top: 0;
  padding-right: 5px;
  padding-left: 5px;
}

.login-container {
    display: table-caption;
      border-radius: 10px;
      border: 1px solid grey;
    
    display: table-caption;
    width: 200px;
}

#navigation h1 {
  color: white;
}

#navigation button {
  background-color: transparent;
  border: none;
  align-self: center;
}

#navigation button:hover {
  cursor: pointer;
}

#navigation button:focus {
  outline: none !important;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.app-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.phone-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-image img {
  max-width: 100%;
  max-height: 100%;
}

.register-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}



.left-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;

}

.right-content {
  flex: 1;
  display: flex;
  align-items: center;
  float: left;
  background-color: #fff;
}





.top-bar {
  color: #fff;
  padding: 10px;
}



nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

nav ul li {
  margin-left: 10px;
}

nav ul li:first-child {
  margin-left: 0;
}