.chatOnlineFriend {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}

.chatOnlineImgContainer {
  position: relative;
  margin-right: 10px;
}

.chatOnlineImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgb(208, 12, 247);
}

.badge {
  color: rgb(10, 241, 80);
}

.content {
  color: rgb(233, 186, 186);
}




@media screen and (max-width: 768px) {
  .chatOnlineName {
    display: none;
  }
}
.chatOnlineBadge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.online {
  background-color: green;
}

.offline {
  background-color: red;
}