.profile {
    display: flex;
    overflow: auto;
}

.profileRight {
    flex: 9;
}

.profileCover {
    height: 320px;
    position: relative;
}

.profileCoverImg {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.profileUserImg {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 150px;
    border: 3px solid white;
}

.profileUserImgChange {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 7em;
    right: 0;
    margin: auto;

    border: 3px solid white;

    background-color: darkgrey;
}

/* .profileUserImgChange svg {
    margin: 3px;
    background-color: aqua;
    width: 1000px;
} */

.profileInfo {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    align-items: start;
    font-size: xx-small;
}

.profileInfoName {
    font-size: 24px;
}

.profileInfoDesc {
    font-weight: 300;
}

.profileRightBottom {
    display: flex;


}


.profile-title {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 3rem;
    color: #000000;
    text-decoration: underline;
}


.profile-image-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image-div img {
    width: 100%;
}

.user-action {
    background-color: rgba(255, 255, 255, 0.658);
    /* padding-top: 1rem; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.user-actions {
    background-color: yellow;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.postImgAdmin {
    margin: 0 auto 0 auto;
    height: 8em;
    width: 8em;
    border-radius: 1.5rem;
    object-fit: cover;


}

.card-input input {
    background-color: #d0d1d8 !important;
    padding: .3rem;
    transition: .3s;
    border: none;
    width: 70%;
}

.add-input:focus {
    background-color: #86af9c !important;
    transform: scale(1.15) !important;
    color: rgb(172, 139, 97) !important;
    outline: none;
}

.card-input:focus {
    background-color: #d4c821 !important;
    transform: scale(1.15) !important;
    color: rgb(126, 148, 180) !important;
}

@keyframes borderAnimation {
    from {
        border: 1px solid #8c8da0;
    }

    50% {
        border: 1px solid #80809b;
    }

    to {
        border: 1px solid #8e8c99;
    }
}

@media screen and (max-width: 800px) {

    .card-input:focus,
    .add-input:focus {
        transform: scale(1.02) !important;
    }

    .card {
        width: 93vw;
    }

    .card-image__post img {
        width: 93vw;
    }
}

#comm {
    display: block;
    text-align: center;

    margin: auto;
    height: 40px;
}

.shareIcon {
    font-size: 18px;
    margin-right: 3px;
}