 .searchbar {
     max-width: 100px;
     outline: auto;
     display: flex;
     border-radius: 20px;

 }


 .searchIcon {
     margin-right: 10px;
     color: #7f8c8d;
     cursor: pointer;
 }

 .searchInput {

     width: 100%;
     background: var(--sds-webc-color-surface-pagebg);


     border: 1px solid var(--sds-webc-color-content-secondary);
     color: var(--sds-webc-color-content-secondary);
     transition: all .2s ease-in-out;
     align-items: baseline;
     display: flex;

 }

 .searchResults {
     display: block !important;

     top: 50px;
     left: 0;
     z-index: 999;
     width: 100%;
     background-color: #f3bfeb;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 }

 .user {

     cursor: pointer;
 }

 .user:hover {
     background-color: #5cf226;
 }