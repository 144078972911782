.share {

  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  width: auto;
  margin: auto;
}

.shareWrapper {
  padding: 10px;
}

.shareTop {
  width: 100%;
  height: 30px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.shareProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.shareInput {
  border: none;
  width: 100%;
}

.shareInput:focus {
  outline: none;
}

.shareHr {
  margin-top: 12px;
}

.shareBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareOptions {
  display: flex;
  margin-left: 20px;
}

.shareOption {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.shareIcon {
  font-size: 18px;
  margin-right: 3px;
}

.shareOptionText {
  font-size: 14px;
  font-weight: 500;
}

.shareButton {
  border: none;
  padding: 2px;
  border-radius: 5px;
  background-color: rgb(76, 78, 76);
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
  color: white;
}

.shareImgContainer {
  padding: 0 20px 10px 20px;
  position: relative;
}

.shareImg {
  width: 100%;
  object-fit: cover;
}

.shareCancelImg {
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  opacity: 0.7;
}

.emoji-picker-react {
  position: absolute !important;
}